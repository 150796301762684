/* ------------------------------ */
/* General
/* ------------------------------ */
html, body, #__next{
    min-height: 100%;
}
body.loading:before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.3);
    z-index: 9998;
}
body.loading:after {
    content: '';
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 60px;
    height: 60px;
    background-image: url(../../public/assets/img/loader.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 9999;
    background-position-y: center;
}
main {
    display: block;
    left: 0;
    margin: 0 auto;
}
.clear-margin-padding {
    margin: 0 !important;
    padding: 0 !important;
}
.text-center {
    text-align: center;
}
.half-width {
    width: 50%;
}
.hide {
    display: none !important;
}
.show {
    display: block;
}
.container {
    max-width: 100%;
    padding: 18px;
}
.pac-container {
    z-index: 3333;
    background-color: #ffffff;
}
a {
    text-decoration: none;
    color: inherit;
}
.row-center {
    align-items: center;
}
.column {
    display: flex;
    flex-direction: column;
}
.flex-grow {
    flex-grow: 1;
}
.space-between {
    justify-content: space-between;
}
.space-around {
    justify-content: space-around;
}
.relative {
    position: relative;
}
.full-center {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-15 {
    margin: 15;
}
/* .center {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.cat-label {
    background-color: #000000;
    color: #ffffff;
    padding: 5px;
    border-radius: 5px;
    margin-left: 5px;
    font-size: 12px;
    top: 0px;
}
@media (max-width: 767px) {
    main {
        max-width: 100vw;
        overflow-x: hidden;
    }
}
.cms-container {
    padding: 0 16px;
    text-align: justify;
}
.cms-container .cms-content-important {
    background: #f5f5f5;
    padding: 16px;
    border-radius: 8px;
}
.cms-container a {
    color: #006bb4;
}
.cms-container a:hover,
.cms-container a:active {
    color: #444;
}
.cms-container .social-icon {
    padding: 0 6px;
    vertical-align: middle;
    display: inline-block;
}
.cms-container .social-icon i:before {
    content: '';
    background-position: center;
    background-size: contain;
    width: 40px;
    height: 40px;
    display: block;
}
.cms-container .social-icon .icon-facebook:before {
    background-image: url(../../public/assets/img/facebook.png);
}
.cms-container .social-icon .icon-twitter:before {
    background-image: url(../../public/assets/img/twitter.png);
}
.cms-container .social-icon .icon-instagram:before {
    background-image: url(../../public/assets/img/instagram.png);
}
.cms-container .social-icon .icon-pinterest:before {
    background-image: url(../../public/assets/img/pinterest.png);
}
.wrapper-brands .slick-track{
    display: flex;
    align-items: center;
}

.grid {
    background-color: #ffffff;
}

.grid .grid-item {
    
}


.link-button {
    color: #007bdb;
}

.link-button:hover {
    text-decoration: underline;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.MuiFormControlLabel-label {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

body div::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
body div::-webkit-scrollbar-thumb {
    background: #B1BCDA;
    border-radius: 20px;
    border: 2px solid #ECEFF7;
}
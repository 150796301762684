.gjs-one-bg {
    background-color: #ffffff !important;
}
.gjs-pn-btn:hover{
    color: #D227A7 !important;
}
.gjs-four-color-h:hover{
    color: #D227A7 !important;
}

.gjs-blocks-c {
    padding: 5px 6px 10px 6px !important;
}

.gjs-pn-btn.gjs-pn-active {
    background-color: #D227A7 !important;
    color: #ffffff !important;
    border-radius: 4px !important;
}

.gjs-four-color-h {
    background-color: #F5F5F5 !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 4px !important;
}

.gjs-one-color {
    color: #463a3c
}

.gjs-one-color-h:hover {
    color: #463a3c
}

.gjs-two-bg {
    background-color: #b9a5a6
}

.gjs-two-color {
    color: #4B5563 !important
}

.gjs-two-color-h:hover {
    color: #b9a5a6
}

.gjs-three-bg {
    background-color: #fff
}

.gjs-three-color {
    color: #D227A7;
}

.gjs-three-color-h:hover {
    color: #D227A7;
}

.gjs-four-bg {
    background-color: #d97aa6;
}

.gjs-four-color {
    color: #d97aa6;
}

.gjs-four-color-h:hover {
    color: #d97aa6
}

/*override*/
.gjs-title,
.gjs-layer-name,
.gjs-no-app,
.gjs-sm-title{
    font-weight: 500 !important;
}


.gjs-editor .gjs-cv-canvas {
    background-color: #F3F4F6 !important;
    top: 55px;
    height: calc(100% - 55px);
}

.gjs-color-warn {
  color: #000;
}

.gjs-clm-tags #gjs-clm-checkbox,
.gjs-clm-tags #gjs-clm-close {
    color: #000 !important;
}

.gjs-title {
    font-size: 14px;
    background-color: #ffffff !important;
    color: #374151;
    font-weight: 700 !important;
    border: none !important;
    padding: 16px !important;
}

.gjs-pn-views-container {
    padding-top: 55px !important;
    box-shadow: none !important;
    border-left: 1px solid #E5E7EB;
}

.gjs-pn-panel.gjs-pn-commands,
.gjs-pn-panel.gjs-pn-devices-c,
.gjs-pn-panel.gjs-pn-options,
.gjs-pn-panel.gjs-pn-views {
    padding: 12px 16px;
}

.gjs-pn-panel.gjs-pn-commands,
.gjs-pn-panel.gjs-pn-views {
    box-shadow: none;
    border-bottom: 1px solid #E5E7EB;
}
.gjs-pn-panel.gjs-pn-views {
    border-left: 1px solid #E5E7EB;
}

.gjs-block-category {
    border-bottom: 1px solid #E5E7EB !important;
}
.gjs-block-category.gjs-open .gjs-title {
    padding-bottom: 0 !important;
}

.gjs-sm-sector-title,
.gjs-title {
    display: flex;
    justify-content: space-between;
}

.gjs-sm-sector-title {
    font-weight: 700 !important;
}

.gjs-sm-sector:not(.gjs-sm-open) .gjs-sm-sector-title::after,
.gjs-block-category:not(.gjs-open) .gjs-title::after {
    content: '';
    background-image: url(../../public/assets/img/editor/chevron-down.svg);
    width: 20px;
    height: 20px;
}
.gjs-sm-sector.gjs-sm-open .gjs-sm-sector-title::after,
.gjs-block-category.gjs-open .gjs-title::after {
    content: '';
    background-image: url(../../public/assets/img/editor/chevron-down.svg);
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
}

.gjs-sm-sector-title .gjs-sm-sector-caret,
.gjs-title .gjs-caret-icon {
    display: none;
}

.gjs-block-label {
    font-size: 12px !important;
}

.gjs-block__media svg {
    width: 48px;
}

.gjs-sm-label.gjs-four-color {
    color: rgba(255, 0, 0, 0.875);
    font-weight: 500;
}
.gjs-color-warn {
    color: #ed9600 !important;
    font-weight: 500;
}

/* Inputs */
.gjs-field {
    background-color: #F6F6F6 !important;
    border: 1px solid #EEEEEE !important;
}
.gjs-sm-field select, .gjs-sm-field input {
    color: rgb(75, 85, 99) !important;
}
.gjs-field-arrows > .gjs-field-arrow-u {
    border-bottom: 4px solid #4B5563;
}
.gjs-field-arrows > .gjs-field-arrow-d {
    border-top: 4px solid #4B5563;
}
.gjs-radio-item:first-child {
    border-left: none !important;
}
.gjs-radio-item {
    border-left: 2px solid #FFFFFF !important;
}
.gjs-radio-item:hover {
    background-color:  #d227a711 !important;
}
.gjs-radio-item input:checked+.gjs-radio-item-label {
    background-color: #d227a7c9 !important;
    color: white;
    font-weight: 500;
}

.gjs-editor-cont .sp-container button,
.gjs-editor-cont .sp-container button:hover,
.gjs-editor-cont .sp-container button:active {
    background: #000000 !important;
    color: #FFFFFF !important;
}

/* Panel Settings */
.gjs-traits-label {
    padding: 12px 10px !important;
    font-size: 14px;
    font-weight: 700 !important;
}
.gjs-field-wrp--button > button {
    background-color: #DDDDDD;
    font-size: 13px;
    font-weight: 600;
}
.gjs-label {
    font-weight: 600;
}
.gjs-clm-tags .gjs-clm-tag {
    background-color: #D227A7 !important;
}
.gjs-clm-tags .gjs-clm-tag * {
    color: #FFFFFF;
}
.gjs-clm-tags #gjs-clm-new {
    color: #4B5563 !important;
}


/* Panel Style Manager */
.gjs-sm-sectors .gjs-sm-sector {
    border-bottom: 1px solid #E5E7EB !important;
}

.gjs-sm-sectors .gjs-sm-sector .gjs-sm-properties {
    padding: 0 8px 12px 8px !important;
}
.gjs-sm-sectors .gjs-sm-sector .gjs-fields .gjs-sm-properties {
    background-color: #F6F6F6 !important;
}
.gjs-sm-field.gjs-sm-composite {
    border: 1px solid #DDDDDD !important;
}
.gjs-sm-sectors .gjs-sm-sector .gjs-fields .gjs-sm-properties .gjs-field {
    background-color: #FFFFFF !important;
    border: 1px solid #DDDDDD !important;
}

.gjs-sm-sector-title {
    background-color: #ffffff !important;
    border: none !important;
    padding: 12px 8px !important;
}

#gjs-sm-add {
    color: #4B5563 !important;
}
.gjs-sm-label {
    font-weight: 500;
}


/* Panel Layer */
.gjs-layer .gjs-layer-title {
    background-color: #FFFFFF;
    border-bottom: #67232e !important;
}

.gjs-layer.gjs-selected .gjs-layer-title {
    background-color: #F1F1F1 !important;
}


/* Modal */
.gjs-mdl-dialog {
    max-width: 952px !important;
    border-radius: 8px !important;
    overflow: hidden;
}
.gjs-mdl-container {
    background-color: rgba(0, 0, 0, 0.7) !important;
}
.gjs-mdl-container .gjs-am-file-uploader > form,
.gjs-mdl-container .gjs-am-assets-cont {
    background-color: unset !important;
}
.gjs-mdl-container.custom-modal .gjs-mdl-dialog .gjs-mdl-header {
    display: none;
}
.gjs-mdl-container.custom-modal .gjs-mdl-dialog .gjs-mdl-content {
    padding: 0 !important;
    overflow: auto !important;
    max-height: 90vh;
}

.gjs-mdl-container:not(.custom-modal) .gjs-mdl-header {
    padding: 20px 24px 16px;
    font-size: 16px;
    font-weight: 700;
    border-bottom: none;
}

.gjs-mdl-container:not(.custom-modal) .gjs-mdl-content {
    padding: 8px 24px 40px;
}
.gjs-mdl-container:not(.custom-modal) .gjs-am-file-uploader form {
    background-color: #F9FAFB !important;
    border: 1px solid #D1D5DB;
    border-radius: 6px;
}

.gjs-mdl-container:not(.custom-modal) .gjs-am-assets-cont {
    padding: 0 0 0 24px;
}

.gjs-mdl-container:not(.custom-modal) .gjs-am-assets-cont .gjs-am-assets-header {
    margin-bottom: 16px;
    padding: 0 0 0 5px;
}

.gjs-mdl-container:not(.custom-modal) .gjs-am-assets-cont .gjs-am-assets-header .gjs-field {
    border: none !important;
}
.gjs-mdl-container:not(.custom-modal) .gjs-am-assets-cont .gjs-am-assets-header .gjs-field input {
    padding: 10px 16px;
    background-color: white;
    border: 1px solid #D1D5DB;
    border-radius: 6px;
}

.gjs-mdl-container:not(.custom-modal) .gjs-am-assets-cont .gjs-am-assets-header .gjs-btn-prim {
    background: #BE1F93;
    color: white;
    padding: 11px 16px;
    width: fit-content;
    border-radius: 6px;
    font-weight: 600;
}
/* Custom Block Icon */
/* .gjs-block:hover .custom-block-icon path {
    fill: #D227A7;
    transition: all .2s ease 0s;
}
.gjs-block:hover .custom-block-icon rect {
    stroke: #D227A7;
    transition: all .2s ease 0s;
} */